<template>
  <div class="d-flex flex-column align-items-center">
    <img
      src="https://apiserver.com.br/image/uploads/colmeia/3.svg"
      alt=""
      style="width: 250px"
    />
    <b-card class="px-4" style="width: 400px">
      <b-row>
        <label>Digite a nova senha:</label>
        <b-input type="password" v-model="password"></b-input>
      </b-row>
      <b-row class="mt-4">
        <label>Confirme a nova senha:</label>
        <b-input type="password" v-model="confirm_password"></b-input>
      </b-row>
      <b-row class="d-flex mt-4 justify-content-end">
        <b-button variant="primary" @click="save_change">Salvar</b-button>
      </b-row>
    </b-card>
    <b-alert v-if="success" class="mt-2" show variant="success">{{
      success
    }}</b-alert>
    <b-alert v-if="error" class="mt-2" show variant="danger">{{
      error
    }}</b-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      password: "",
      confirm_password: "",
      error: "",
      success: "",
    };
  },
  methods: {
    save_change() {
      if (this.password == this.confirm_password) {
        if (this.password.length >= 6) {
          const response = this.$store.dispatch(
            "change_password/save_password",
            { id: this.id, password: this.password }
          );
          if (response) {
            this.success = "Senha alterada com sucesso!";
            window.location.href = "https://painel.colmeia.io";
          } else {
            this.errorMsg("Erro ao gravar alteração", 5000);
          }
        } else {
          this.errorMsg("A senha deve conter mais de 5 caracteres", 5000);
        }
      } else {
        this.errorMsg("As senhas devem ser idênticas", 5000);
      }
    },
    errorMsg(msg, timer) {
      this.error = msg;
      setTimeout(() => {
        this.error = "";
      }, timer);
    },
  },
  mounted() {
    const id = this.$route.params.user_id;
    if (id) {
      this.id = id;
    }
  },
};
</script>

<style>
body {
  background-color: #efeeea;
}
</style>
